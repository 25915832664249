<template>
  <v-container>
    <h2 class="primary--text">Suivi de la facturation</h2>
<!--    <v-row no-gutters justify="space-between">
      <v-checkbox v-model="search.all" label="Voir tout les financements"></v-checkbox>
      <v-btn @click="resetFilters" color="red" class="white&#45;&#45;text"><v-icon>mdi-trash-can</v-icon>Effacer les filtres</v-btn>
    </v-row>-->
    <v-checkbox label="Voir en TTC" v-model="showInTTC"></v-checkbox>
    <v-row no-gutters justify="center">
      <v-col cols="4">
        <v-card color="orange" class="ma-4 pa-3 text-center">
          <h2 class="white--text">Reste à facturer AISFT<br> {{ totaux.AISFT.toFixed(2) }} €</h2>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card color="primary" class="ma-4 pa-3 text-center">
          <h2 class="white--text">Reste à facturer MOE<br> {{ totaux.MOE.toFixed(2) }} €</h2>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card color="green" class="ma-4 pa-3 text-center">
          <h2 class="white--text">Total à facturer<br> {{ totaux.total.toFixed(2) }} €</h2>
        </v-card>
      </v-col>
    </v-row>
    <v-simple-table class="mt-4" fixed-header dense>
      <template>
        <thead>
        <tr>
          <th>Nom du bénéficiaire</th>
          <th>Numéro d'arrété</th>
          <th>Statut</th>
          <th>AISFT</th>
          <th>MOE</th>
          <th>Reste à facturer AISFT</th>
          <th>Reste à facturer MOE</th>
          <th>Reste à facturer total</th>
          <th>Actions</th>
        </tr>
        <tr></tr>
        <tr>
          <th>
            <v-autocomplete small-chips :items="computed_dossiers" item-text="fullname" item-value="id" multiple label="Noms" v-model="search.names"></v-autocomplete>
          </th>
          <th><v-select dense deletable-chips multiple small-chips :items="active_years" v-model="search.annees"></v-select></th>
          <th>
            <v-select label="Statuts" :items="statuts" item-text="name" item-value="id" deletable-chips multiple small-chips v-model="search.statuts"></v-select>
          </th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="dossier in computed_dossiers" :key="dossier.id">
          <td><router-link class="text-decoration-none" :to="'/finances/' + dossier.id">{{dossier.beneficiaire_last_name}} {{ dossier.beneficiaire_first_name }}</router-link></td>
          <td>{{ dossier.numero_arrete }}</td>
          <td style="max-width: 100px; word-wrap: break-word">{{dossier.current_statut}}</td>
          <td>{{ (8500.00 / tvaCoef).toFixed(2) }} €</td>
          <td>{{ (dossier.maitrise_oeuvre / tvaCoef).toFixed(2) }} €</td>
          <td>
            {{ dossier.resteAFacturerAISFT.toFixed(2) }} €
          </td>
          <td>
            {{ dossier.resteAFacturerMOE.toFixed(2) }} €
          </td>
          <td>
            {{ (dossier.resteAFacturerAISFT + dossier.resteAFacturerMOE).toFixed(2) }} €
          </td>
          <td>
            <HonoraireForm v-if="loaded_clients" :preloaded-clients="true" title="Facturer" @reload="reloadDossiers" :dossier="dossier"/>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import {PROVENANCES, TYPES_ENCAISSEMENT} from "@/constants/Financement";
import {EntrepriseRepository} from "@/repository/entreprises";
import * as config from '@/config.json';
import {DateUtilMixin} from "@/mixins/DateUtilMixin";
import dayjs from "dayjs";
import HonoraireForm from "@/views/HonoraireForm.vue";

export default {
  name: "SuiviFacturationGlobal",
  components: {HonoraireForm},
  mixins: [DateUtilMixin],
  async mounted() {
    this.dossiers = await this.$store.dispatch("dossier/getAllDossierFinanceData");
    let entrepriseRepo = new EntrepriseRepository();
    this.entreprises = await entrepriseRepo.fetch();
    let statusFetchStatus = this.$store.getters["dossier/statuts/statut_fetch_status"];
    if (statusFetchStatus === "INITIAL" || statusFetchStatus === "ERROR") {
      await this.$store.dispatch("dossier/statuts/getStatuts");
    }

    this.clients = await this.$store.dispatch("clients/fetchClients");
    this.loaded_clients = true;
  },
  data() {
    return {
      reglements: [],
      dossiers: [],
      search: {
        names: [],
        annees: [],
        statuts: [],
      },
      showInTTC: false,
      types_encaissement: ["Tout", ...TYPES_ENCAISSEMENT],
      provenances: ["Tout", ...PROVENANCES],
      entreprises: [],
      justificatifSelect: [
        {name: "Tous", value: null},
        {name: "Avec justificatif", value: "avec"},
        {name: "Sans justificatif", value: "sans"},
      ],
      baseUrl: config.BASE_URL,
      loaded_clients: false,
    }
  },
  computed: {
    statuts() {
      return this.$store.getters["dossier/statuts/statuts"];
    },
    tvaCoef() {
      return this.showInTTC ? 1.0 : 1.085;
    },
    active_years() {
      let activeYears = [];
      for(var i = 2020; i < dayjs().year() + 1; i++) {
        activeYears.push(i);
      }
      return activeYears;
    },
    computed_dossiers() {
      let that = this;
      return this.dossiers
          .filter(dossier => dossier.numero_arrete !== null && dossier.numero_arrete !== "")
          .map(function (dossier) {
        let totalPayeAISFT = 0;
        let totalPayeMOE = 0;
        for(const saisie of dossier.saisies) {
          if(saisie.type === "Décaissement" && saisie.source === "AISFT") {
            totalPayeAISFT += parseFloat(saisie.total);
          }
          if(saisie.type === "Décaissement" && saisie.source === "MOE") {
            totalPayeMOE += parseFloat(saisie.total);
          }
        }
        totalPayeAISFT /= that.tvaCoef;
        totalPayeMOE /= that.tvaCoef;
        const resteAFacturerAISFT = (8500 / that.tvaCoef - totalPayeAISFT);
        const resteAFacturerMOE = (dossier.maitrise_oeuvre / that.tvaCoef - totalPayeMOE);
        return {...dossier, totalPayeAISFT, totalPayeMOE, resteAFacturerAISFT, resteAFacturerMOE, fullname: dossier.beneficiaire_last_name + " " + dossier.beneficiaire_first_name }
      }).filter(dossier => this.search.names.length > 0 ? this.search.names.includes(dossier.id) :  true)
          .filter(dossier => this.search.statuts.length > 0 ? this.search.statuts.includes(dossier.statut_id) : true)
          .filter(this.filterAnnee);
    },
    totaux() {
      let initial = {
        AISFT: 0,
        MOE: 0,
        total: 0,
      }
      return this.computed_dossiers.reduce((a, b) => {
        a.AISFT += (b?.resteAFacturerAISFT || 0);
        a.MOE += (b?.resteAFacturerMOE || 0);
        a.total += (b?.resteAFacturerAISFT + b?.resteAFacturerMOE || 0);
        return a;
      }, initial);
    },
    total_of_filter() {
      let total = 0;
      for(let reglement of this.filtered_reglements) {
        if (reglement.montant !== null) {
          total += parseFloat(reglement.montant);
        }
      }
      return total;
    }
  },
  methods: {
    async reloadDossiers() {
      this.dossiers = await this.$store.dispatch("dossier/getAllDossierFinanceData");
    },
    filterAnnee(dossier) {
      if (this.search.annees.length > 0) {
        if(dossier.numero_arrete === null) return false;
        let annee = 2000 + parseInt( dossier.numero_arrete.substring(0,2));
        return this.search.annees.includes(annee);
      } else {
        return true;
      }
    },
    resetFilters() {
      this.search = {
        name: null,
        source: "Tout",
        entreprise: null,
        justificatif: null,
        all: false,
      };
    }
  }

}
</script>

